@import-normalize;
@import "~@o4c/css/src/foundation";
@import "~@o4c/css/src/core/fonts";
@import "~@o4c/css/src/components/tabs";
@import "~@o4c/css/src/components/pills";
@import "~@o4c/css/src/components/buttons";
@import "~@o4c/css/src/mixins/elevations";
@import '~@o4c/css/src/components/dropdowns';

* {
  // Prevent blue touch callout backgrounds
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--grey-lighter);
  color: var(--dark);
  margin: 0;
  padding: 0;
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: var(--dark);
  text-decoration: none;
}

.o4c.pill {
  margin-right: 5px;
  margin-bottom: 14px;
}

.container {
  padding: 24px 16px;
}

.top-navigation {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  min-height: 96px;
  @include elevation4();
  .tabs {
    position: relative;
    z-index: 20;
  }
  .screen-header {
    position: relative;
    z-index: 10;
  }
}

.screen-header {
  @include smallText();
  font-weight: bold;
  height: 48px;
  background: var(--white);
  display: flex;
  flex-grow: 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  overflow: hidden;
  > div,
  > div > div:not([class]) {
    background: var(--white);
    min-height: 48px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > a,
    > button {
      display: flex;
      flex-basis: 44px;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 48px;
      background: transparent;
      font-size: 16px;
      text-decoration: none;
      border: 0;
      margin: 0;
      padding: 0 8px;
      span {
        @include elevation1();
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 32px;
        width: 32px;
        border-radius: 4px;
        border: 1px solid opacitize(--black, 0.2);
      }
      &.action {
        &:first-child {
          justify-content: left;
        }
        &:last-child {
          justify-content: right;
        }
      }
    }
  }
}

.card.muted {
  i,
  .icon {
    color: var(--grey-dark);
    &::before {
      color: var(--grey-dark);
    }
  }

  .calendar-button {
    flex: 0;
    padding-left: 8px;
  }
}
