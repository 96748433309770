.indicator-column {
    display: flex;
    flex-direction: column;

    .indicator-spacer {
        flex: 1;
    }

    .note-indicator {
        position: relative;
        justify-self: flex-end;
    }
}
