@import "~@o4c/css/src/mixins/typography";

.calendar {
  width: 100%;
  background: var(--white);
  .calendar-weekdays {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--grey-light);
    > div {
      @include extraSmallText();
      line-height: 30px;
      flex: 1;
      color: var(--grey-dark);
      text-align: center;
    }
  }
  .calendar-week {
    display: flex;
    width: 100%;
    overflow: hidden;
    &:last-child {
      .calendar-day {
        height: 68px;
        padding-bottom: 20px;
      }
    }
  }
  .calendar-previous-month,
  .calendar-next-month,
  .calendar-day {
    @include smallText();
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 40px;
    height: 48px;
    text-align: center;
    border: 0;
    border-left: 1px solid var(--grey-light);
    background: var(--white);
    position: relative;
    .day-label {
      @include extraSmallText();
      color: var(--grey-dark);
      position: absolute;
      top: 4px;
    }
    .date {
      display: block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border: 0;
      border-radius: 20px;
      text-align: center;
    }
    .calendar-event {
      height: 8px;
      position: absolute;
      z-index: 5;
      top: 36px;
      border-radius: 2px;
      &.event-shift {
        background: var(--primary);
      }
    }
    &:first-child {
      border: 0;
    }
    &.weekend {
      background: var(--light);
    }
    &.other-month * {
      opacity: 0.2;
    }
    &.today {
      .date {
        background: var(--accent);
        color: var(--on-accent);
        font-weight: bold;
      }
    }
    &.holiday {
      background: var(--light);
    }
  }
  .calendar-previous-month,
  .calendar-next-month {
    text-transform: uppercase;
    padding: 0 12px;
    font-weight: bold;
    color: var(--primary);
    i,
    .icon {
      &:before {
        color: var(--primary);
      }
    }
  }
}

$sliderHeight: 64px;
.calendar.slider {
  height: $sliderHeight;
  width: 100%;
  border-top: 1px solid var(--grey-light);
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .calendar-previous-month,
  .calendar-next-month,
  .calendar-day {
    height: $sliderHeight;
    flex: 0 0 52px;
    &.selected {
      box-shadow: inset 0 0 0 2px var(--accent);
    }
    &.other-month {
      display: none;
    }
    .calendar-event {
      bottom: 8px;
      top: auto;
    }
  }
}
