.preferencesDialogTitle {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px;
    margin: auto 0;

    .titleText {
        flex: 4;
        margin: auto;
        font-size: medium;
        font-weight: 700;
        text-transform: capitalize;
    }

    .dialogCloseButton {
        margin: auto;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
}

.listLabel {
    display: flex;
    flex-direction: row;
    margin: 16px 4px;
}

.listLabelText {
    flex: 1;
    font-weight: bold;
}

.selectAll {
    cursor: pointer;
    color: var(--primary);
    font-size: small;
    font-weight: 600;
    text-transform: uppercase;
}