.contentWrapper {
    display: flex;
    flex-direction: row;
    height: calc(90vh - 96px);
    background-color: var(--grey-lighter);
}


.emptyActivitiesContent {
    display: flex;
    flex-direction: column;
    gap: 64px;
    
    width: 100%;
    
    padding: 0 48px;
    margin: auto;
}

.emptyActivitiesText {
    text-align: center;
    margin: auto;
    color: var(--grey-dark)
}