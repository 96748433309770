.input-switch {
  display: flex;
  background: var(--grey-lighter);
  border: 1px solid var(--grey-lighter);
  border-radius: 3px;
  padding: 1px;
  position: relative;
  z-index: 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    background: var(--primary);
    transition: left 0.3s ease-in-out;
    left: 1px;
  }
  &.active {
    &::before {
      left: 50%;
    }
    button {
      &:first-child {
        color: var(--dark);
      }
      &:last-child {
        color: var(--on-primary);
      }
    }
  }
  button {
    position: relative;
    z-index: 2;
    display: block;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: transparent;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
    &:first-child {
      color: var(--on-primary);
    }
    &:last-child {
      color: var(--dark);
    }
  }
}