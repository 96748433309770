.shift-resources {
    border: 1px solid var(--grey);
    border-radius: var(--card-border-radius);
    -moz-border-radius: var(--card-border-radius);
    -webkit-border-radius: var(--card-border-radius);
    padding: 16px;
    margin-top: 16px;
    width: calc(100% - 32px);

    .shift-resource:last-child {
        margin-bottom: 0px;
    }

    .shift-resource {
        flex: 0 0 100%;
        margin-bottom: 16px;

        span {
            font-size: 14px;
        }

        span.description {
            flex: 1;
        }

        .display-name {
            font-weight: bold;
        }
        .resource {
            display: flex;
            justify-content: space-between;
        }
    }

}
