.preferencesDialogTitle {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 12px;
    margin: auto 0;
    border-bottom: 1px solid var(--grey-light);

    .titleText {
        flex: 4;
        margin: auto;
        font-size: medium;
        font-weight: 700;
    }

    .dialogCloseButton {
        margin: auto;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
}

:global {
    .MuiDialogContent-root {
        padding: 0 !important;
        overflow: auto !important;
    }


}