.label {
    margin: auto;
}

.filter {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.filterSelected {
    font-weight: 400;
    font-size: small;
    color: var(--grey-dark);
}

.accordionTitle {
    display: flex;
    flex-direction: row;
    gap: 16px;
    font-weight: 600;
    color: var(--primary);
}

.titleLabel {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.expandIcon {
    margin: auto;
}

:global {

    .MuiAccordion-root.Mui-expanded {
        padding: 0 !important;
        margin: 0 !important;
        padding-bottom: 0;
    }

    .MuiAccordionSummary-root {
        border-bottom: 1px solid var(--secondary);
    }

    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
        border-bottom: 1px solid var(--grey-light) !important;
    }

}

.filterButton {
    :global {
        .o4c.button.outline {
            border: none;
        }
    }
}