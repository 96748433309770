@-webkit-keyframes show {
    0% {
        z-index: -1;
        opacity: 0;
    }
    1% {
        z-index: 9999;
        opacity: 0;
    }
    100% {
        z-index: 9999;
        opacity: 1;
    }
}

@keyframes show {
    0% {
        z-index: -1;
        opacity: 0;
    }
    1% {
        z-index: 9999;
        opacity: 0;
    }
    100% {
        z-index: 9999;
        opacity: 1;
    }
}
@-webkit-keyframes hide {
    0% {
        z-index: 9999;
        opacity: 1;
    }
    1% {
        z-index: 9999;
        opacity: 0;
    }
    100% {
        z-index: -1;
        opacity: 0;
    }
}

@keyframes hide {
    0% {
        z-index: 9999;
        opacity: 1;
    }
    1% {
        z-index: 9999;
        opacity: 0;
    }
    100% {
        z-index: -1;
        opacity: 0;
    }
}

.spinner {
    margin: 0 auto;
    width: 72px;
}

.spinner > div {
    width: 18px;
    height: 18px;
    margin: 0 3px;
    background-color: var(--primary);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: spinner 1.4s infinite ease-in-out both;
    animation: spinner 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes spinner {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes spinner {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
