@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;subset=latin-ext");
@import '~@o4c/css/src/components/text-fields';

body {
  font-family: var(--font-family);
  font-size: 12px;
  background-color: var(--grey-lighter);
  overflow-x: auto;
  margin: 0 auto;
}

.app {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.positive-text {
  color: var(--positive);
}

.negative-text {
  color: var(--negative);
}

.primary-text {
  color: var(--primary-color);
}

.secondary-text {
  color: var(--grey-dark);
}

.flex-gap {
  gap: 15px;
  display: flex;
}

.flex-gap-item {
  flex: 1 1;
}

.h-100 {
  height: 100%;
}
