.accordionContent {
    padding: 16px 32px;
    max-height: calc(100vh - 160px) !important;
    overflow: auto;
    background-color: var(--grey-lighter);
}

.accordionTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-weight: 600;
}

.filterSelected {
    font-weight: 400;
    font-size: small;
    text-transform: lowercase;
    color: var(--grey-dark);
}

.titleLabel {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.label {
    flex: 1;
    margin: auto;
    color: var(--primary);
}

:global {
    .MuiAccordion-root.Mui-expanded {
        padding: 0 !important;
        margin: 0 !important;
        padding-bottom: 0;
        max-height: min-content !important;
    }

}