.team-table-container {
    overflow-x: auto;
    height: calc(100vh - 96px);

    table.team-table {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
        position: relative;
        background-color: var(--white);

        td,
        th {
            line-height: 30px;
            font-size: 11px;
            height: 50px;
            min-width: 120px;
            width: 120px;
            text-align: center;
        }
        th {
            border-top: 1px solid var(--grey-light);
            border-bottom: 1px solid var(--grey-light);
            border-right: 1px solid var(--grey-light);
        }

        td th:first-child {
            width: 24px;
            min-width: 24px;
            max-width: 24px;
        }

        .tile-activities {
            min-width: 120px !important;
        }

        .daypart td {
            width: 1024px;
            border-right: 1px solid var(--grey-light);
            height: auto;
            padding: 4px 8px;
            text-align: left;
        }

        .row-0 td {
            border-top: 1px solid var(--grey-light);
            border-bottom: 1px dashed var(--grey-light);
        }
        .row-1 td {
            border-bottom: 1px dashed var(--grey-light);
        }

        .row-2 td {
            border-bottom: 1px solid var(--grey-light);
        }

        td.head {
            text-align: center;
            padding: 0;
            min-width: 24px;
            width: 24px;
            font-weight: 700;
            font-size: 10px;
        }

        tr.name {
            td {
                position: sticky;
                left: 0;
                outline: 0;
                text-align: left;
                font-weight: 700;
                font-size: 16px;
                height: 25px;
                padding-left: 8px;
                background-color: var(--grey-lighter);
            }
            .name-cell {
                z-index: 1;
            }
        }

        th {
            width: 100%;
            position: sticky;
            top: 0;
            z-index: 1000;
            background-color: var(--white);
            font-weight: 400 !important;
            height: 64px;
            
        }

        td.thead-container {
            padding: 0;
            position: sticky;
            top: 0;
            z-index: 1000;

            .dates {
                display: flex;
                background-color: transparent;
                .day-tile {
                    display: flex;
                    flex-direction: column;
                    span {
                        margin: 0;
                        font-size: 12px;
                    }
                    span.dates-day {
                        text-align: center;
                        width: 100%;
                        font-size: 11px;
                        color: var(--grey-light-dark);
                    }

                    .active {
                        border-radius: 50%;
                        background-color: var(--accent);
                        color: var(--white);
                        width: 15px;
                        margin: auto;
                        height: 15px;
                        line-height: 15px;
                        font-weight: bold;
                        text-align: center;
                    }

                    .not-active {
                        width: 15px;
                        height: 15px;
                        margin: auto;
                        line-height: 15px;
                    }
                }

                .dates-shifts {
                    bottom: 8px;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .shift-bullet {
                        display: inline-block;
                        width: 10px;
                        height: 8px;
                        border-radius: 2px;
                        margin: 0px 1px 0px 1px;
                        background-color: var(--grey-light);
                    }
                }
            }
        }
    }

    .tile-activities {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.screen-header {
    .calendar-button {
        flex: 0;
        margin-left: 8px;
    }
    .team-nav {
        display: flex;
        margin: auto;
        margin: 0 32px;

        .team-nav-date {
            flex: 1;
            text-align: center;
        }
    }

    .settings-button {
        flex: 0;
        margin-right: 8px;
    }
}
