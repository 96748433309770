.activity {
    font-size: 12px;
    border-radius: 4px;
    margin-right: 4px;
    display: flex;

    .activity-text {
        flex: 1;
        padding: 4px 8px;
    }
}

.detail-dialog-title {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 15px;
    margin: auto 0;

    .dialog-title-text {
        flex: 4;
        margin: auto;
        text-transform: capitalize;
        font-size: medium;
        font-weight: 700;
    }

    .dialog-close-button {
        margin: auto;
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }

}

.detail-dialog-content-wrapper{
    padding: 16px 32px;
}

.detail-dialog-content {
    background-color: white;

}

.MuiDialog-paper{
    position: absolute !important;
    width: 100% !important;
    margin: 0 !important;
    bottom: 0;
}
