.teamTableContainer {
    overflow-x: scroll;
    height: calc(100vh - 96px);
}

.dayparts {
    box-shadow:
        0px -1px 2px rgba(0, 0, 0, 0.05),
        0px 4px 8px rgba(0, 0, 0, 0.2);
}

.daypartText {
    margin: auto;
    font-size: 12px;
    font-weight: 700;
}

.name {
    position: sticky;
    left: 0;
    outline: 0;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    height: 25px;
    padding-left: 8px;
    background-color: var(--grey-lighter);
}

.preferencesContainer {
    padding: 16px 32px !important;
}

.nameCell {
    z-index: 1;
}

.th {
    border-top: 1px solid var(--grey-light);
    border-bottom: 1px solid var(--grey-light);
    border-right: 1px solid var(--grey-light);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: var(--white);
    font-weight: 400 !important;
}

.daypartCell {
    border-top: 1px solid var(--grey-light);
    border-bottom: 1px solid var(--grey-light);
    border-right: 1px solid var(--grey-light);
    height: auto;
}

.tbody {
    margin-top: 40px;
}

.teamTable {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    position: relative;

    td:first-child {
        border-left: 1px solid var(--grey-light);
    }
}

.sourcesContainer {
    min-width: 226px;
    flex-shrink: 0;
    margin: 4px;
    margin-bottom: 16px;
}
