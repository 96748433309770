.preferenceCard {
    display: flex;
    margin: 12px;
    flex-direction: row;
    gap: 8px;

    .cardText {
        margin: auto;
        flex: 1;
    }
}