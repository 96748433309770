.sourceCard {
    position: relative;
    width: inherit;
    border-radius: 4px;
    border: 1px solid  #D6D6D6;
    background:  #FFF !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px -0.25px 0.5px 0px rgba(0, 0, 0, 0.01);
    margin-bottom: 4px;
    padding: 8px;
    cursor: pointer;
}

.noteIndicator {
    position: absolute;
    height: 16px !important;
    width: 16px !important;
    bottom: 0;
    right: 0;
}

.singleSource {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 4px;
}