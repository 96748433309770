* {
    box-sizing: border-box;
}
.activitiesHeader {
    background: white;
    height: 48px;
    padding: 8px !important;
    display: flex;
    gap: 16px;
    align-items: center;
}

.dateActions {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dateBtn {
    padding: 8px;
    cursor: pointer;
}

.dateText {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
}

.iconBtn {
    box-sizing: border-box;
    padding: 8px;
    flex-grow: 0 !important;
}

.hide-scroll {
    &::-webkit-scrollbar { display: none; } 
    -ms-overflow-style: none; 
    scrollbar-width: none;
}